import { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import { CARD_DEFINITION } from './details-config';
import { Box, Cards, CardsProps, Grid, Link, Pagination, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { getMidwayJwtToken } from '../../auth/MidwayJwtToken';
import { getAppSetting } from '../../config/AppSettings';

export default function Details() {

  const pipelineData = useParams<{pipelineName : string}>();
  const [allItems, setAllItems] = useState<
  {
    pipelineName: string; 
    datacraft_pipeline_name: string;
    account: string;
    latest_step_function_workflow_id: string;
    execution_id: string;
    cti: string;
    conversions: [[]];
    dq: [[]]
  }>({
    pipelineName: '',
    datacraft_pipeline_name: '',
    account:'',
    latest_step_function_workflow_id: '',
    execution_id: '',
    cti: '',
    conversions: [[]],
    dq: [[]]
  });

  const displayPipelineData = async (pipelineName) => {
    var jwtToken = await getMidwayJwtToken();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+jwtToken);
    const apiBasePath = `https://${getAppSetting('apiUrl')}/jwt`;
    fetch(`${apiBasePath}/pipelines/Details/${pipelineName}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    })
    .then(response => response.json())
    .then(response => setAllItems(response))
    .then(result => console.log(result))
    .catch(error => console.log('error', error));  
  }

  useEffect(() => {
    (async () => {
      (displayPipelineData(pipelineData.pipelineName))
    })();
  }, [Cards]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    [allItems] || [],
    {
      filtering: {},
      pagination: {
        pageSize: 4
      },
      sorting: {},
      selection: {}
    }
  );

  return (
    <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
      <Grid
          gridDefinition={[
            { colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
          ]}
        >
    <Cards
    cardDefinition={CARD_DEFINITION} 
    header={
      <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
               <Button variant="normal" href="#/">Back to Home Page</Button>
            </SpaceBetween>
          }
        >
          Pipeline Details
      </Header>
    }  
    items={items}
    loadingText="Loading results"
    cardsPerRow={[
      { cards: 1 },
      { minWidth: 500, cards: 1 }
    ]}
    stickyHeader
           />
    </Grid>
    </Box>
  );
}