import  {Route, Switch} from 'react-router';
import Homepage from './home';
import CommonHeader from './CommonHeader';
import Details from './details/Details';

export default function App() {
  return (
    <div>
      <CommonHeader />  
      <Switch>
      
      <Route exact path="/" component={Homepage} />
      <Route path="/Details/:pipelineName" component={Details} />
      </Switch>
      
      
   </div>
  );
}

