import { Link } from '@amzn/awsui-components-react';

export const CARD_DEFINITION = 
    {
      header: item => item.pipelineName,
      sections: [
        {
          id: 'datacraftPipelineName',
          header: 'Datacraft Pipeline Name',
          content: item => <Link external externalIconAriaLabel="Opens in a new tab" href = {getUrl(item.datacraft_pipeline_name.toString())}>{item.datacraft_pipeline_name}</Link>
        },
        {
          id: 'cti',
          header: 'CTI',
          content: item => item.cti,
        },
        {
          id: 'execution_id',
          content: item => item.execution_id,
          header: 'Execution id',
        },
        {
          id: 'account',
          header: 'Account Id',
          content: item => item.account,
        },
        {
          id: 'conversions',
          header: 'Conversion Jobs',
          content: item => item.conversions.map(i => i.map( (j,idx) => {
              var helper = [
                "Datanet profile Id",
                "Cradle Job URL",
                "Job stats"
              ]
              if(idx==1)
              return <div>{helper[idx]+": "} 
                <Link
                  external
                  externalIconAriaLabel="Opens in a new tab"
                  href= {j.toString()}
                >
                  {j}
                </Link>
              </div>
              else if(idx==0)
              return <div>{helper[idx]+": "+j.toString()}</div>
            })
            ),
        },
        {
          id: 'dq',
          header: 'DQ',
          content: item => item.dq.map(i => i.map( (j,idx) => {
              var helper = [
                "Is a reverse DQ job",
                "Cradle Job URL",
                "Job stats"
              ]
              if(idx==1)
              return <div>{helper[idx]+": "} 
                <Link
                  external
                  externalIconAriaLabel="Opens in a new tab"
                  href= {j.toString()}
                >
                  {j}
                </Link>
              </div>
              else if(idx==0)
              return <div>{helper[idx]+": "+j.toString()}</div>
            })
            ),
        },
        {
          id: 'latest_step_function_workflow_id',
          header: 'Latest step function workflow id',
          content: item => item.latest_step_function_workflow_id,
        },
      ]
    };

    function getUrl(pipelineName: string) {
      var url = "https://datacentral.a2z.com/datacraft/pipeline/"+pipelineName+"/"
      return url
    }