import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import { PipelinesApiFactory } from '../../pipelines-api/generated-src/api'
import { Pipeline } from '../../pipelines-api/generated-src/api';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

export default function PipelinesTable() {
  const [allItems, setAllItems] = useState<Pipeline[]>();
  const [refreshTableFlag, setRefreshTableFlag] = useState(false);
  const [maxId, setMaxId] = useState(0);
  const history = useHistory();
  const PipelinesApi = PipelinesApiFactory();


  useEffect(() => {
    (async () => {
      setAllItems( (await PipelinesApi.listPipelines()).data );
    })();
  }, [refreshTableFlag]);

  useEffect(() => {
    setMaxId( 1 + (items.length > 0 ? Math.max(...items.map(i=>i.id)) : 0 ) );
  }, [allItems]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems || [],
    {
      filtering: {
       
        
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        )
      },
      pagination: {
        pageSize: 40
      },
      sorting: {},
      selection: {}
    }
  );

  function handleClick(pipelineName: string){
    history.push(`Details/${pipelineName}`);
  }
 

  const { selectedItems } = collectionProps;
  return (
    <Table
      {...collectionProps}
      loading={!allItems}
      loadingText="Loading instances"
      selectionType="single"
      
      header={
        <Header
          counter={
            allItems &&
            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
          }
          
          actions={
            <SpaceBetween direction="horizontal" size="m">
               <Button  
                 
                 disabled={selectedItems!.length == 0}
                 onClick={() => handleClick(selectedItems![0].pipelineName!)}
                 variant="primary" 
                 
               >
                 Details
               </Button>
 
            </SpaceBetween>
          }
        >
        
            <p></p> 
          Records
        </Header>

        
      }
      
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount!)}
          filteringAriaLabel="Filter records"
        />
      }
    />
  );
}
