import Box from '@amzn/awsui-components-react/polaris/box';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { Pipeline } from '../../pipelines-api/generated-src/api';
import { ReactNode } from 'react';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Pipeline>[] = [
  {
    id: 'pipelineName',
    header: 'Pipeline Name',
    cell: item => item.pipelineName,
    minWidth: '180px'
  },
  // Commented IDs are the extra information, to be further displayed on the details page
  // {
  //   id: 'sk',
  //   cell: item => item.sk,
  //   header: 'Sort Key',
  //   minWidth: '160px'
  // },
  {
    id: 'accountid',
    header: 'Account Id',
    cell: item => item.accountId,
    minWidth: '100px'
  },
  // {
  //   id: 'conversionJobs',
  //   header: 'Conversion Jobs',
  //   cell: item => item.conversionJobs,
  //   minWidth: '100px'
  // },
  {
    id: 'cti',
    header: 'CTI',
    cell: item => item.cti,
    minWidth: '100px'
  },
  // {
  //   id: 'dedupeJobs',
  //   header: 'Dedupe Jobs',
  //   cell: item => item.dedupeJobs,
  //   minWidth: '100px'
  // },
  // {
  //   id: 'version',
  //   header: 'Version',
  //   cell: item => item.version,
  //   minWidth: '100px'
  // },
  // {
  //   id: 'workflowId',
  //   header: 'Workflow Id',
  //   cell: item => item.workflowId,
  //   minWidth: '100px'
  //  }
  //,
  // {
  //   id: 'workflowState',
  //   header: 'Details',
  //   cell: item => item.workflowState,
  //   minWidth: '100px'
  // }
  

];

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' }
];
